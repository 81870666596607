<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="content_margin_bottom">
      <div class="row">
        <div class="col-md-12 mb-4">
          <h4 class="card-title my-3 float-right">
            <router-link
              :to="`/entity/${entityId}/courses`"
              class="btn btn-success btn-sm"
              >Courses</router-link
            >
          </h4>
        </div>
      </div>
      <section>
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="form-group">
                  <label for="unit_name">Title</label>
                  <input
                    type="text"
                    placeholder="Enter Test Title"
                    id="unit_name"
                    class="form-control"
                    v-model="content.unit_name"
                  />
                </div>
                <div class="form-group">
                  <label for="pass_score">Pass Score</label>
                  <input
                    type="number"
                    placeholder="Enter Pass Score"
                    id="pass_score"
                    class="form-control"
                    v-model="content.pass_score"
                  />
                </div>
                <div class="form-group">
                  <label for="max_attempts">Maximum Number of Attempts</label>
                  <input
                    type="number"
                    placeholder=" Maximum Number of Attempts"
                    class="form-control"
                    v-model="content.max_num_attempts"
                  />
                </div>
                <div class="form-group">
                  <label for="duration">Duration in seconds</label>
                  <input
                    type="number"
                    placeholder=" Maximum Number of Attempts"
                    class="form-control"
                    v-model="content.duration_in_secs"
                  />
                </div>
                <div class="form-group">
                  <label for="duration">Test Description</label>
                  <input
                    type="text"
                    placeholder="test description"
                    class="form-control"
                    v-model="content.description"
                  />
                </div>
                <div v-show="content.questions">
                  <div
                    v-for="(res, i) in content.questions"
                    :key="`input-${i}`"
                  >
                    <div class="form-group">
                      <label for="product">
                        <span class="font-weight-bolder">{{
                          i == 0 ? i + 1 : i + 1
                        }}</span
                        >. Question</label
                      >
                      <textarea
                        type="text"
                        placeholder="Enter Question"
                        id="price"
                        class="form-control"
                        v-model="res.ques"
                      />
                    </div>
                    <div class="form-group">
                      <label for="weight">Weight</label>
                      <input
                        type="number"
                        placeholder="Enter Weight"
                        id="weight"
                        class="form-control"
                        v-model="res.weight"
                      />
                    </div>
                    <div class="divide_section">
                      <div v-for="(answer, j) in res.answers" :key="j">
                        <div class="form-group  p-2 m-0">
                          <label for="answer"> Choice</label>
                          <input
                            type="text"
                            placeholder="Enter Answer"
                            id="answer"
                            class="form-control"
                            v-model="answer.choice"
                          />
                          <div class="form-group d-flex m-3">
                            <div class="form-check form-check-inline">
                              <label for="inliner" class="m-0 pr-2"
                                >Correct Answer?</label
                              >
                              <input
                                class="form-check-input"
                                type="radio"
                                @change="correctAnswer(j, i)"
                                v-model="answer.correct"
                                id="inliner"
                                :value="
                                  answer.correct == true ||
                                  answer.correct == 'True'
                                    ? answer.correct
                                    : ''
                                "
                                :checked="answer.correct == true ? true : false"
                              />
                              <label class="form-check-label" for="inliner"
                                >Yes
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center mb-4">
                      <div class="removeItem">
                        <i
                          class="btn-outline-danger btn-sm"
                          @click="removeInputs(i, content.questions)"
                          >Delete Question</i
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center">
                  <button
                    class="btn btn-outline-success btn-sm"
                    @click="addQuestion"
                  >
                    Add Question
                  </button>
                </div>
                <div class="row justify-content-end">
                  <div class="py-2 py-3">
                    <button
                      @click.prevent="submitQuestions"
                      class="btn btn-primary btn-block my-btn float-right"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
</template>

<script>
/* eslint-disable no-unused-vars */
import store from "@/state/store";
import Layout from "../../layout/index.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Toaster from "@/mixins/toasterMixin.js";

export default {
  page: {
    title: "Edit Test",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    PageHeader,
    Layout,
  },
  data() {
    return {
      title: "Edit Test",
      requestError: "",
      items: [
        {
          text: "Entity Admin",
        },
        {
          text: "Edit Test",
          active: true,
        },
      ],
      contentTypeId: null,
      newInputs: [],
      content: {
        entity_id: parseInt(this.$route.params.id),
        course_id: parseInt(this.$route.params.courseId),
        unit_name: "",
        pass_score: "",
        max_num_attempts: "",
        duration_in_secs: "",
        questions: [
          {
            ques: "",
            weight: "",
            answers: [
              {
                choice: "",
              },
              {
                choice: "",
              },
              {
                choice: "",
              },
              {
                choice: "",
              },
            ],
          },
        ],
      },
      entityId: this.$route.params.id,
      contentId: this.$route.params.contentId,
      isChoiceCorrect: [false, false],
      error: null,
      success: null,
      loading: false,
    };
  },
  methods: {
    correctAnswer(j, i) {
      this.content.questions[i].answers.forEach((answer) =>
        answer.correct == "True"
          ? (answer.correct = "False")
          : (answer.correct = "False")
      );
      this.content.questions[i].answers[j].correct = "True";
    },
    addQuestion() {
      let questions = {
        ques: "",
        weight: parseInt(""),
        answers: [
          {
            choice: "",
            correct: Boolean(""),
          },
          {
            choice: "",
            correct: Boolean(""),
          },
          {
            choice: "",
            correct: Boolean(""),
          },
          {
            choice: "",
            correct: Boolean(""),
          },
        ],
      };
      this.content.questions.push(questions);
    },
    removeInputs(index, field) {
      field.splice(index, 1);
    },
    submitQuestions() {
      this.loading = true;
      this.error = null;
      this.success = null;

      const data = {
        test: this.content,
        testId: this.content.id,
      };

      console.log(data);

      // DISPATCHING DATA TO THE BACKEND
      store
        .dispatch("contents/updateTest", data)
        .then((response) => {
          if (response.status == 200) {
            this.toast({ message: "Test Updated", type: "success" });
            console.log(response.data);
            this.loading = false;

            this.$router.push(
              `/entity/${this.$route.params.id}/course/${this.$route.params.courseId}/test/${this.$route.params.contentId}`
            );
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          if (err?.response?.data == undefined) {
            this.loading = false;
            Toaster({
              message:
                "Network error. Check your network connection and try again.",
              type: "error",
            });
          }
        });
    },
  },
  computed: {
    getTest() {
      return this.$store.getters.question;
    },
  },
  created() {
    store
      .dispatch("contents/viewContent", this.contentId)
      .then((res) => {
        const content = res.data;
        this.content.unit_name = content.unit_name;
        this.content.pass_score = content.pass_score;
        this.content.max_num_attempts = content.max_num_attempts;
        this.content.duration_in_secs = content.duration_in_secs;

        const contentTypeId = content.id;
        this.contentTypeId = contentTypeId;

        this.content = content;
        console.log(content);
      })
      .catch((err) => {
        if (err?.response?.data === undefined)
          this.requestError = "An error occured";
      });
  },
};
</script>

<style scoped>
label {
  color: #000;
}

@media (min-width: 768px) {
  .divide_section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.removeItem {
  color: red;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: 20px;
  width: 50% !important;
  justify-content: center;
}
.removeItem > i {
  font-size: 20px;
}

#multi {
  width: 100%;
}

#multi > div {
  margin: 0 20px 20px 20px;
  width: 100%;
}

.table-wrapper {
  background: #fff;
  padding: 20px 25px;
  margin: 30px 0;
  border-radius: 2px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.247);
}
.table-title {
  padding-bottom: 15px;
  background: linear-gradient(40deg, #ff2020, #05ffa3);
  color: #fff;
  padding: 16px 30px;
  margin: -20px -25px 10px;
  border-radius: 1px 1px 0 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.247);
}
.table-title h2 {
  margin: 5px 0 0;
  font-size: 24px;
}
.my-btn {
  width: 150px;
}
fieldset {
  width: inherit;
  padding: 0 10px;
  border-bottom: none;
}
</style>
